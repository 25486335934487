import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import img from "../images/about-b-corp.jpg"
import styles from "./bcorpFeature.module.scss"

export default function ClientLogos() {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          bcorp: entry(id: 20882) {
            uri
          }
        }
      }
    `
  )

  return (
    <div className="wrapper">
      <div className="columns is-centered">
        <div className="column is-10-widescreen">
          <div className={styles.module}>
            <div className={styles.image}>
              <img src={img} alt="Certified B Corporation" />
            </div>
            <div className={styles.body}>
              <div className="mb-1">
                <h3 className="h-3 mb-3 h--cms-title">
                  Business should <em>sustain and enrich everyone</em>. Read why
                  we're a B&nbsp;Corp&trade;.
                </h3>
              </div>
              <Link className="link" to={`/${data.craft.bcorp.uri}`}>
                Tell me more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
