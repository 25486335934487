import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Marquee from "../components/marquee"
import HeadingCta from "../components/headingCta"
import BCorpFeature from "../components/bcorpFeature"
import styles from "./about.module.scss"

import HeaderGraphic from "../images/about-desktop.svg"
import HeaderGraphicMobile from "../images/about-mobile.svg"
import img from "../images/about-icon.png";
import imgMobile from "../images/about-icon-mobile.png";


export const query = graphql`
  query {
    craft {
      entry(section: "about") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_about_about_Entry {
          headingCta {
            ...HeadingCta
          }
          alternateTitle
          marqueeSection {
            ... on Craft_marqueeSection_BlockType {
              id
              scrollingTitle
              leftText
              rightText
            }
          }
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const entry = data.craft.entry
  const seo = entry.seomatic
  let title = entry.alternateTitle != null ? entry.alternateTitle : entry.title

  return (
    <Layout seo={seo}>
      <div className="page-content-module">
        <div className={styles.module}>
          <div className="wrapper">
            <div className="text-center">
              <h1
                className="h-1 h--cms-title mb-4"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></h1>
              <div className={styles.svgDesktop}>
                <img src={img} alt="About" />
              </div>
              <div className={styles.svgMobile}>
                <img src={imgMobile} alt="About" />
              </div>
            </div>
          </div>
        </div>
        {entry.marqueeSection.map((item, i) => (
          <Marquee
            key={`marquee-${item.id}`}
            title={item.scrollingTitle}
            left={item.leftText}
            right={item.rightText}
            direction={i % 2 === 0 ? 1 : -1}
          />
        ))}
        <BCorpFeature />
      </div>
      <HeadingCta data={entry.headingCta[0]} />
    </Layout>
  )
}

export default AboutPage
